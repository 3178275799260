/* Below 992px Screen */

@media (min-width: 768px) and (max-width: 1024.99px) {
  .c-wrapper {
    overflow-x: hidden !important;
  }
  .container,
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 675px !important;
  }
}
@media (min-width: 1025px) and (max-width: 1199.99px) {
  .c-wrapper {
    overflow-x: hidden !important;
  }
  .container,
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 850px !important;
  }
}

@media (min-width: 768px) and (max-width: 1199.99px) {
  .c-wrapper {
    overflow-x: hidden !important;
  }
  section {
    position: relative !important;
  }

  /* Font Sizes */
  .fs-100 {
    font-size: 75px;
    line-height: 75px;
  }
  .fs-105 {
    font-size: 85px;
    line-height: 85px;
  }
  .fs-120,
  .fs-130,
  .fs-210,
  .fs-150 {
    font-size: 90px;
    line-height: 90px;
  }
  .fs-180,
  .fs-200,
  .fs-205 {
    font-size: 120px;
    line-height: 120px;
  }
  .fs-220,
  .fs-240 {
    font-size: 130px;
    line-height: 130px;
  }
  .fs-250 {
    font-size: 150px;
    line-height: 150px;
  }
  .fs-300,
  .fs-330,
  .fs-360,
  .fs-380 {
    font-size: 200px;
    line-height: 0.825;
  }
  .fs-420 {
    font-size: 170px;
    line-height: 0.825;
  }
  .fs-510 {
    font-size: 210px;
    line-height: 0.825;
  }
  .fs-600 {
    font-size: 300px;
    line-height: 300px;
  }

  /* First Section */
  #section-home .fs-200 {
    margin: 0;
    padding: 0;
    font-size: 100px;
    line-height: 90px;
    letter-spacing: -1.5px;
  }
  .section-home-headers {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 470px !important;
  }
  .section-home-headers .h-150px {
    height: 115px;
  }
  .section-home-headers-01 .fs-200 {
    text-align: left;
    margin-left: 20px;
  }
  .section-home-headers-02 {
    top: -38px;
    text-align: right;
    margin-right: 20px;
    color: var(--color-second);
  }
  .section-home-headers-03 {
    margin-left: 0;
  }
  .section-home-headers-04 {
    top: -100px;
  }
  #section-home {
    position: relative;
  }
  #section-home .h4 {
    margin-top: 32px;
    font-size: var(--h6);
  }
  #section-home .mobile-section-home {
    margin-top: -56px;
  }
  #section-home .w-550 {
    max-width: 450px;
  }

  .button {
    font-size: var(--h6);
  }
  .button.button-lg {
    padding: 15px 40px;
    border-radius: 10px;
    font-size: var(--h3);
  }

  .mobile-sticky-bottom {
    right: 0;
    left: 50%;
    width: 100%;
    bottom: 50px;
    max-width: 550px;
    position: absolute;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  #woah .fs-500,
  #woah .fs-60,
  #walletHistory .fs-205,
  #wrappedReady .fs-300,
  #walletHistory .fs-205,
  #woah .fs-60,
  #walletHistory .fs-60 {
    white-space: pre-wrap;
  }

  #woah .fs-500 {
    font-size: 200px;
    line-height: 180px;
    font-family: 'SuperluminaLight', sans-serif !important;
  }
  #woah .fs-60,
  #walletHistory .fs-60 {
    font-size: 40px;
    letter-spacing: 0.02em;
  }
  #woah .fs-500 span,
  #woah .fs-60 span,
  #wrappedReady .fs-300 span,
  #walletHistory .fs-205 span {
    width: 100%;
    display: inline-block;
  }

  #walletHistory .fs-205,
  #wrappedReady .fs-300 {
    font-size: 150px;
  }
  #walletHistory .fs-205 {
    font-size: 130px;
    line-height: 110px;
  }

  .size-170 {
    width: 95px;
    height: 95px;
  }
  .size-170.ml-20 {
    margin-left: 20px !important;
  }

  .second-screen .size-220,
  .sixth-screen .size-220,
  .eighth-screen .size-220 {
    width: 150px;
    height: 150px;
  }

  /* Welcome Section */
  .welcome-section .fs-180 {
    font-size: 85px;
    line-height: 1;
  }
  .welcome-section {
    padding: 120px 0 !important;
  }
  .welcome-section .mb-350 {
    margin-bottom: 150px;
  }

  .mb-250,
  .mb-300 {
    margin-bottom: 125px;
  }
  .mb-135 {
    margin-bottom: 75px;
  }
  .mb-175 {
    margin-bottom: 125px;
  }
  .mb-200 {
    margin-bottom: 105px;
  }

  .fs-40 {
    font-size: 20px;
    line-height: 1.25;
  }
  .fs-100,
  .fs-180 {
    font-size: 70px;
    line-height: 60px;
  }
  .fs-180 {
    font-size: 75px;
    line-height: 1.1;
  }
  .fs-120 {
    font-size: 75px;
    line-height: 1.1;
  }

  /* Section Two */
  .second-screen,
  .third-screen,
  .sixth-screen,
  .ninth-screen,
  .tenth-screen,
  .eleventh-screen {
    padding-top: 110px;
  }
  .second-screen {
    padding-bottom: 110px;
  }
  .section-two-graphic {
    top: -20px;
    left: -60px;
    width: 200px;
    height: 320px;
  }
  .second-screen .fs-150 {
    font-size: 85px;
    line-height: 1.1;
  }
  .fs-150-stroke {
    color: transparent;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: var(--color-white);
  }
  .second-screen .fs-250 {
    font-size: 200px;
    line-height: 180px;
  }
  .mbn-50 {
    margin-bottom: -20px !important;
  }
  .second-screen .size-170.mbn-50,
  .second-screen .size-220.mbn-60,
  .sixth-screen .size-220.mbn-60 {
    margin-bottom: -20px !important;
  }
  .second-screen .size-220.mr-16,
  .sixth-screen .size-220.mr-16 {
    margin-right: 90px !important;
  }

  .eighth-screen .size-220.mbn-60 {
    margin-bottom: -30px !important;
  }

  .second-screen .blb .pt-5,
  .sixth-screen .blb .pt-5,
  .eighth-screen .blb .pt-5,
  .ninth-screen .blb .pt-5 {
    width: 100%;
    max-width: 65%;
    margin-top: 10px;
    position: relative;
    padding-left: 60px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 70px !important;
    text-align: right !important;
  }
  .second-screen .blb .pt-5 span,
  .sixth-screen .blb .pt-5 span,
  .eighth-screen .blb .pt-5 span,
  .ninth-screen .blb .pt-5 span {
    text-align: right !important;
  }
  .second-screen .blb .pt-5::before,
  .sixth-screen .blb .pt-5::before,
  .eighth-screen .blb .pt-5::before,
  .ninth-screen .blb .pt-5::before {
    top: 0;
    left: 4%;
    width: 100%;
    content: ' ';
    max-width: 50px;
    position: absolute;
    height: calc(100% - 25px);
    border-left: 3px solid var(--color-fourth);
    border-bottom: 3px solid var(--color-fourth);
  }

  .second-screen .duck-graphic {
    top: 90px;
    right: -20%;
    width: 200px;
    height: 215px;
    display: block;
    position: absolute;
  }
  .second-screen .w-40.blb.mx-auto {
    margin-left: 0 !important;
    padding-left: 5px;
  }
  .second-screen .w-40.blb .pt-5::before {
    left: 0px;
    max-width: 0;
  }
  .second-screen .w-40.blb.mx-auto .w-100.text-right {
    text-align: left !important;
  }

  .fs-360 {
    font-size: 180px;
  }

  .big-round-circle {
    z-index: 2;
    border-radius: 50%;
    position: relative;
    background-color: var(--color-white);
    box-shadow: 0 0 0 40px var(--color-white);
  }
  .big-round-circle::before {
    top: 50%;
    left: 50%;
    z-index: -1;
    width: 110%;
    height: 110%;
    position: absolute;
    background-color: #fff;
    content: ' ';
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  .big-round-circle-wrap .fs-100 {
    font-size: 75px;
    line-height: 70px;
  }

  /* Section Three */

  .third-screen .fs-300,
  .eighth-screen .fs-300 {
    font-size: 150px;
  }
  .third-screen .fs-120,
  .eighth-screen .fs-120 {
    font-size: 65px;
    line-height: 60px;
  }

  /* Section Four */
  .fourth-screen .currently-holding .fs-510 {
    font-size: 250px;
    line-height: 0.825;
  }
  .big-rounded-box {
    border-radius: 30px;
    margin-bottom: 35px;
  }
  .big-rounded-box > div {
    padding: 30px;
  }
  .big-rounded-box .big-box-text {
    font-size: 160px;
  }
  .big-box-body {
    height: calc(100% - 50px);
  }
  .big-box-body .fs-100 {
    font-size: 60px;
  }
  .big-box-footer {
    height: 50px;
    font-size: 20px;
    margin-bottom: -10px;
  }
  .big-rounded-box .size-250 {
    max-width: 95px;
  }
  .fourth-screen .fs-130 {
    font-size: 80px;
    line-height: 80px;
  }

  /* Fifth Screen */
  .fifth-screen {
    padding: 200px 10px 100px;
  }
  .section-fifth-graphic {
    top: 160px;
    right: -90px;
    width: 250px;
    height: 400px;
  }
  .fifth-screen .currently-holding .mbn-50,
  .fifth-screen .currently-holding .mbn-40 {
    margin-bottom: -20px !important;
  }
  .fifth-screen .currently-holding .fs-510 {
    font-size: 150px;
  }
  .nft-graph {
    margin: 0 -10px;
  }
  .nft-graph-items {
    padding: 0 10px;
  }
  .fifth-screen .fs-130 {
    font-size: 70px;
    line-height: 70px;
  }

  .nft-graph,
  .nft-graph-items {
    height: 540px;
  }
  .nft-graph-line {
    height: 540px;
    max-width: 100%;
    min-height: 350px;
    border-radius: 20px;
    margin-bottom: 20px;
  }
  .nft-graph-name {
    top: 20px;
    max-width: 100%;
    letter-spacing: 0.5px;
  }
  .nft-graph-name,
  .nft-graph-name.row1,
  .nft-graph-name.row2 {
    font-size: 20px !important;
  }
  .nft-graph-count {
    font-size: 20px !important;
  }

  /* Sixth Screen */
  .sixth-screen,
  .seventh-screen,
  .eighth-screen {
    padding: 160px 0;
  }
  .sixth-screen .fs-220 {
    font-size: 100px;
    line-height: 1;
  }
  .sixth-screen .blb .pt-5::before {
    left: 4%;
    border-left: 3px solid var(--color-green_sh1);
    border-bottom: 3px solid var(--color-green_sh1);
  }
  .sixth-screen .duck-graphic2 {
    left: -25%;
    width: 370px;
    height: 405px;
    display: block;
  }
  .sixth-screen .fs-100 {
    font-size: 85px;
    line-height: 1.1;
  }

  /* Seventh Screen */
  .seventh-screen .fs-220 {
    font-size: 110px;
    line-height: 1.1;
  }
  .seventh-screen .mb-300 {
    margin-bottom: 175px;
  }

  /* Eighth Screen */
  .eighth-screen {
    padding: 150px 0 100px;
  }
  .eighth-screen .fs-250 {
    font-size: 210px;
    line-height: 210px;
  }
  .eighth-screen .blb .pt-5::before {
    left: 4%;
    border-left: 3px solid var(--color-yellow_sh2);
    border-bottom: 3px solid var(--color-yellow_sh2);
  }

  /* Ninth Screen */
  .ninth-screen {
    padding: 150px 0 300px;
    padding-bottom: 600px;
  }
  .ninth-screen .fs-510 {
    font-size: 200px;
    line-height: 1;
  }
  .ninth-screen .blb .pt-5::before {
    left: 4%;
    border-left: 3px solid var(--color-yellow_sh2);
    border-bottom: 3px solid var(--color-yellow_sh2);
  }
  .ninth-screen .mb-250 {
    margin-bottom: 125px;
  }

  /* Tenth Screen */
  .tenth-screen::before,
  .tenth-screen::after {
    top: -200px;
    padding-bottom: 140%;
  }
  .tenth-screen .currently-holding {
    margin-top: -150px !important;
  }
  .mb-400 {
    margin-bottom: 150px;
  }
  .mb-800 {
    margin-bottom: 300px;
  }

  /* Eleventh Screen */
  .eleventh-screen {
    padding-top: 250px;
  }
  .eleventh-screen .section-eleventh-graphic,
  .eleventh-screen .section-eleventh-graphic2 {
    width: 450px;
    height: 720px;
  }
  .eleventh-screen .section-eleventh-graphic {
    left: 30px;
    top: -100px;
  }
  .eleventh-screen .section-eleventh-graphic2 {
    top: 300px;
    right: -100px;
  }
  .eleventh-screen .custom-col {
    max-width: 85% !important;
    flex: 0 0 85% !important;
  }
  .eleventh-screen .fs-205 {
    font-size: 92px;
    line-height: 1;
  }

  /* Enter */
  .enter {
    width: 100%;
    height: 1200px;
    margin-top: -40px;
    max-height: 1200px;
  }
  .enter-inner {
    padding-bottom: 140px;
  }

  .hr-scroll-lists li {
    padding: 0 10px;
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 20px;
  }
  .hr-scroll .size-170 {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 40px 10px;
  }
  .hr-scroll .size-170 img {
    border-radius: 40px 10px;
    border: 5px solid var(--color-white);
  }

  .button.button-lg {
    padding: 14px 40px;
    border-radius: 10px;
    font-size: var(--h4);
  }
  .button.button-lg.mb-70 {
    margin-bottom: 40px;
  }

  .radius-40 {
    overflow: hidden;
    border-radius: 24px;
  }

  .mt-100 {
    margin-top: 70px !important;
  }
  .mint-price {
    font-size: 28px;
  }
}

@media (max-width: 1199.99px) {
  .transitionLine,
  .transitionLine2 {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  #section-two .d-flex.w-100.blb,
  #sixth-screen .d-flex.w-95.blb,
  .w-95.blb,
  .w-90.blb {
    margin-left: auto;
    margin-right: auto;
    max-width: 95% !important;
  }
}
