/* Below 768px Screen */

@media (max-width: 767.99px) {
  .c-wrapper {
    overflow-x: hidden !important;
  }

  section {
    position: relative !important;
  }
  .container,
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 350px !important;
  }

  .mt-100 {
    margin-top: 50px !important;
  }

  /* Font Sizes */
  .fs-100 {
    font-size: 50px;
    line-height: 50px;
  }
  .fs-105 {
    font-size: 55px;
    line-height: 50px;
  }
  .fs-120,
  .fs-130,
  .fs-210,
  .fs-150 {
    font-size: 60px;
    line-height: 60px;
  }
  .fs-180,
  .fs-200,
  .fs-205 {
    font-size: 80px;
    line-height: 80px;
  }
  .fs-220,
  .fs-240 {
    font-size: 90px;
    line-height: 90px;
  }
  .fs-250 {
    font-size: 125px;
    line-height: 125px;
  }
  .fs-300,
  .fs-330,
  .fs-360,
  .fs-380 {
    font-size: 150px;
    line-height: 0.825;
  }
  .fs-420 {
    font-size: 130px;
    line-height: 0.825;
  }
  .fs-510 {
    font-size: 180px;
    line-height: 0.825;
  }
  .fs-600 {
    font-size: 300px;
    line-height: 300px;
  }

  /* First Section */
  #section-home .fs-200 {
    margin: 0;
    padding: 0;
    font-size: 65px;
    line-height: 56px;
    letter-spacing: -1.5px;
  }
  .section-home-headers {
    padding-left: 0;
    padding-right: 0;
  }
  .section-home-headers .h-150px {
    height: 70px;
  }
  .section-home-headers-01 {
    padding-left: 50px;
  }
  .section-home-headers-01 .fs-200 {
    text-align: left;
    margin-left: 20px;
  }
  .section-home-headers-02 {
    top: -38px;
    text-align: right;
    margin-right: 20px;
    color: var(--color-second);
  }
  .section-home-headers-03 {
    margin-left: 0;
  }
  .section-home-headers-04 {
    top: -100px;
  }
  #section-home {
    position: relative;
  }
  #section-home .h4 {
    margin-top: 32px;
    font-size: var(--h6);
  }
  #section-home .mobile-section-home {
    margin-top: -90px;
  }
  .fs-20 {
    font-size: 14px;
    color: var(--color-white);
    font-family: 'Roobert-SemiBold';
  }
  .fs-20.mt-4 {
    margin-top: 40px !important;
  }

  .button {
    font-size: var(--font-body-1);
  }
  .button.button-lg {
    padding: 12px 40px;
    border-radius: 8px;
    font-size: var(--h6);
  }
  .button.button-lg.mb-70 {
    margin-bottom: 40px;
  }

  .mobile-sticky-bottom {
    right: 0;
    left: 50%;
    width: 100%;
    bottom: 35px;
    max-width: 450px;
    position: absolute;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .mobile-sticky-bottom .button {
    width: 100%;
  }

  #woah .fs-500,
  #woah .fs-60,
  #walletHistory .fs-205,
  #wrappedReady .fs-300,
  #walletHistory .fs-205,
  #woah .fs-60,
  #walletHistory .fs-60 {
    white-space: pre-wrap;
  }

  #woah .fs-500 {
    font-size: 200px;
    line-height: 180px;
    font-family: 'SuperluminaLight', sans-serif !important;
  }
  #woah .fs-60,
  #walletHistory .fs-60 {
    font-size: 30px;
    letter-spacing: 0.02em;
  }
  #woah .fs-500 span,
  #woah .fs-60 span,
  #wrappedReady .fs-300 span,
  #walletHistory .fs-205 span {
    width: 100%;
    display: inline-block;
  }

  #walletHistory .fs-205,
  #wrappedReady .fs-300 {
    font-size: 130px;
  }
  #walletHistory .fs-205 {
    font-size: 120px;
    line-height: 110px;
  }

  .size-170 {
    width: 55px;
    height: 55px;
  }
  .hr-scroll .size-170 {
    width: 110px;
    height: 110px;
  }
  .size-170.ml-20 {
    margin-left: 20px !important;
  }

  .second-screen .size-220,
  .sixth-screen .size-220,
  .eighth-screen .size-220 {
    width: 80px;
    height: 80px;
  }

  /* Welcome Section */
  .welcome-section .fs-180 {
    font-size: 52px;
    line-height: 1;
  }
  .welcome-section {
    padding: 120px 0 !important;
  }
  .welcome-section .mb-350 {
    margin-bottom: 150px;
  }

  .mb-250,
  .mb-300 {
    margin-bottom: 125px;
  }
  .mb-135 {
    margin-bottom: 75px;
  }
  .mb-175 {
    margin-bottom: 95px;
  }
  .mb-200 {
    margin-bottom: 105px;
  }

  .fs-40 {
    font-size: 20px;
    line-height: 1.25;
  }
  .fs-100,
  .fs-180 {
    font-size: 50px;
    line-height: 1.1;
  }
  .fs-180 {
    font-size: 45px;
    line-height: 1.1;
  }
  .fs-120 {
    font-size: 75px;
    line-height: 1.1;
  }

  /* Section Two */
  .second-screen,
  .third-screen,
  .sixth-screen,
  .ninth-screen,
  .tenth-screen,
  .eleventh-screen {
    padding-top: 110px;
  }
  .second-screen {
    padding-bottom: 110px;
  }
  .section-two-graphic {
    top: -20px;
    left: -60px;
    width: 200px;
    height: 320px;
  }
  .second-screen .fs-150 {
    font-size: 54px;
    line-height: 1.1;
  }
  .fs-150-stroke {
    color: transparent;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: var(--color-white);
  }
  .second-screen .fs-250 {
    font-size: 100px;
    line-height: 80px;
  }
  .second-screen .size-170.mbn-50,
  .second-screen .size-220.mbn-60,
  .sixth-screen .size-220.mbn-60 {
    margin-bottom: -20px !important;
  }
  .second-screen .size-220.mr-16,
  .sixth-screen .size-220.mr-16 {
    margin-right: 60px !important;
  }
  .sixth-screen .size-220.mbn-50 {
    margin-bottom: -10px !important;
  }

  .eighth-screen .size-220.mbn-60 {
    margin-bottom: -30px !important;
  }

  .second-screen .blb .pt-5,
  .sixth-screen .blb .pt-5,
  .eighth-screen .blb .pt-5,
  .ninth-screen .blb .pt-5 {
    width: 100%;
    margin-top: 10px;
    position: relative;
    padding-left: 60px;
    padding-top: 70px !important;
    text-align: right !important;
  }
  .second-screen .blb .pt-5 span,
  .sixth-screen .blb .pt-5 span,
  .eighth-screen .blb .pt-5 span,
  .ninth-screen .blb .pt-5 span {
    text-align: right !important;
  }
  .second-screen .blb .pt-5::before,
  .sixth-screen .blb .pt-5::before,
  .eighth-screen .blb .pt-5::before,
  .ninth-screen .blb .pt-5::before {
    top: 0;
    left: 0;
    width: 100%;
    content: ' ';
    max-width: 50px;
    position: absolute;
    height: calc(100% - 25px);
    border-left: 3px solid var(--color-fourth);
    border-bottom: 3px solid var(--color-fourth);
  }
  .second-screen .blb .pt-5.repeat::before {
    height: calc(100% - 8px);
  }

  .second-screen .duck-graphic {
    top: 90px;
    right: -20%;
    width: 200px;
    height: 215px;
    display: block;
    position: absolute;
  }
  .second-screen .w-40.blb.mx-auto {
    margin-left: 0 !important;
    padding-left: 5px;
  }
  .second-screen .w-40.blb .pt-5::before {
    left: 0px;
    max-width: 0;
  }
  .second-screen .w-40.blb.mx-auto .w-100.text-right {
    text-align: left !important;
  }

  .fs-360 {
    font-size: 100px;
  }

  .big-round-circle {
    z-index: 2;
    border-radius: 50%;
    position: relative;
    background-color: var(--color-white);
    box-shadow: 0 0 0 40px var(--color-white);
  }
  .big-round-circle::before {
    top: 50%;
    left: 50%;
    z-index: -1;
    width: 110%;
    height: 110%;
    position: absolute;
    background-color: #fff;
    content: ' ';
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  .big-round-circle-wrap .fs-100 {
    font-size: 45px;
    line-height: 40px;
  }

  /* Section Three */
  .fancy-header-title {
    padding-left: 40px;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #403278;
  }
  .fancy-header-title.fs-330 {
    font-size: 90px;
  }
  .fancy-header-title::before {
    top: -40px;
    margin-left: -40px;
  }
  .fancy-header-title::after {
    top: -20px;
    margin-left: -20px;
  }

  .third-screen .fs-300,
  .eighth-screen .fs-300 {
    font-size: 80px;
  }
  .third-screen .fs-120,
  .eighth-screen .fs-120 {
    font-size: 35px;
    line-height: 30px;
  }
  .eighth-screen .fs-200 {
    font-size: 50px;
    letter-spacing: 0;
  }
  .third-screen .fs-80,
  .eighth-screen .fs-80 {
    font-size: 35px;
    line-height: 30px;
  }

  /* Section Four */
  .fourth-screen::before,
  .fourth-screen::after {
    top: -450px;
    padding-bottom: 140%;
  }
  .fourth-screen .fs-510 {
    font-size: 150px;
    font-family: 'SuperluminaBlack', sans-serif !important;
  }
  .fourth-screen .currently-holding .fs-120 {
    font-size: 70px;
    line-height: 60px;
  }
  .fourth-screen .currently-holding .fs-120 .m-block {
    display: block;
    font-size: 140px;
    line-height: 120px;
  }
  .fourth-screen .fs-62 {
    font-size: 30px;
    line-height: 40px;
  }
  .fourth-screen .fs-130 {
    font-size: 60px;
    line-height: 60px;
  }

  .big-rounded-box {
    border-radius: 30px;
    margin-bottom: 35px;
  }
  .big-rounded-box > div {
    padding: 30px;
  }
  .big-rounded-box .big-box-text {
    font-size: 170px;
  }
  .big-box-body {
    height: calc(100% - 60px);
  }
  .big-box-footer {
    height: 60px;
    font-size: 20px;
    margin-bottom: -10px;
  }
  .big-rounded-box .size-250 {
    max-width: 95px;
  }
  .fourth-screen .fs-420 {
    font-size: 84px;
  }
  .fourth-screen .mbn-80 {
    margin-bottom: -35px !important;
  }

  /* Fifth Screen */
  .fifth-screen {
    padding: 200px 10px 100px;
  }
  .section-fifth-graphic {
    top: 160px;
    right: -90px;
    width: 250px;
    height: 400px;
  }
  .fifth-screen .currently-holding .mbn-50,
  .fifth-screen .currently-holding .mbn-40 {
    margin-bottom: -15px !important;
  }
  .fifth-screen .currently-holding .fs-510 {
    font-size: 130px;
  }

  .nft-graph {
    margin: 0 -5px;
  }
  .nft-graph-items {
    padding: 0 5px;
  }

  .nft-graph,
  .nft-graph-items {
    height: 340px;
  }
  .nft-graph-line {
    height: 340px;
    min-height: 200px;
    max-width: 100%;
    border-radius: 15px;
    margin-bottom: 15px;
  }
  .nft-graph-name {
    top: 10px;
    font-size: 12.5px;
    max-width: 100%;
    letter-spacing: 0.5px;
  }
  .nft-graph-name.row1,
  .nft-graph-name.row2 {
    font-size: 12.5px;
  }
  .nft-graph-count {
    font-size: 13px !important;
  }

  /* Sixth Screen */
  .sixth-screen,
  .seventh-screen,
  .eighth-screen {
    padding: 100px 0;
  }
  .sixth-screen .fs-510,
  .seventh-screen .fs-510 {
    font-size: 64px;
    line-height: 1.1;
  }
  .sixth-screen .fs-220 {
    font-size: 55px;
    line-height: 1;
  }
  .sixth-screen .blb .pt-5::before {
    left: 0;
    border-left: 3px solid var(--color-green_sh1);
    border-bottom: 3px solid var(--color-green_sh1);
  }
  .sixth-screen .duck-graphic2 {
    left: -25%;
    width: 200px;
    height: 215px;
    display: block;
  }

  /* Seventh Screen */
  .seventh-screen .fs-220 {
    font-size: 60px;
    line-height: 1.1;
  }

  /* Eighth Screen */
  .eighth-screen {
    padding: 100px 0 50px;
  }
  .eighth-screen .fs-510,
  .ninth-screen .fs-510 {
    font-size: 60px;
    line-height: 1.1;
  }
  .eighth-screen .blb .pt-5::before {
    left: 0;
    border-left: 3px solid var(--color-yellow_sh2);
    border-bottom: 3px solid var(--color-yellow_sh2);
  }
  .eighth-screen .fs-250 {
    white-space: pre-wrap;
  }

  /* Ninth Screen */
  .ninth-screen {
    padding: 150px 0 300px;
  }
  .ninth-screen .size-220 {
    width: 120px;
    height: 120px;
    display: block;
  }
  .ninth-screen .size-220.mbn-60 {
    margin-bottom: -40px !important;
  }
  .ninth-screen .fs-300 {
    font-size: 80px;
  }
  .ninth-screen .fs-120 {
    font-size: 40px;
    line-height: 35px;
  }
  .ninth-screen .blb .pt-5::before {
    left: 0;
    border-left: 3px solid var(--color-yellow_sh2);
    border-bottom: 3px solid var(--color-yellow_sh2);
  }
  .ninth-screen .fs-105 {
    font-size: 50px;
  }
  .ninth-screen .mb-250 {
    margin-bottom: 125px;
  }
  .ninth-screen .fs-250 {
    font-size: 70px;
    line-height: 1.2;
    margin-right: 0 !important;
  }

  /* Tenth Screen */
  .tenth-screen::before,
  .tenth-screen::after {
    top: -200px;
    padding-bottom: 140%;
  }
  .tenth-screen .currently-holding {
    margin-top: -150px !important;
  }
  .tenth-screen .fs-240 {
    font-size: 60px;
    line-height: 60px;
  }
  .mb-400 {
    margin-bottom: 150px;
  }
  .mb-800 {
    margin-bottom: 300px;
  }

  /* Eleventh Screen */
  .eleventh-screen {
    padding-top: 250px;
  }
  .eleventh-screen .currently-holding .fs-150 {
    font-size: 60px;
  }
  .eleventh-screen .fs-500 {
    font-size: 190px;
    line-height: 225px;
  }
  .fs-500-stroke {
    -webkit-text-stroke-width: 8px;
  }
  .eleventh-screen .fs-205 {
    font-size: 55px;
    line-height: 60px;
  }
  .mb-215 {
    margin-bottom: 100px;
  }
  .eleventh-screen .section-eleventh-graphic,
  .eleventh-screen .section-eleventh-graphic2 {
    width: 300px;
    height: 480px;
  }
  .eleventh-screen .section-eleventh-graphic {
    left: 30px;
    top: -100px;
  }
  .eleventh-screen .section-eleventh-graphic2 {
    top: 200px;
    right: -100px;
  }
  .eleventh-screen .mb-135 {
    margin-bottom: 50px;
  }

  /* Enter */
  .enter {
    width: 100%;
    max-height: 600px;
    margin-top: -40px;
  }
  .enter-inner {
    padding-bottom: 100px;
  }

  /* Container Adjustment */
  #section-three .container-fluid {
    max-width: inherit !important;
  }
  #section-two .d-flex.w-100.blb {
    max-width: 95% !important;
    margin-left: auto;
    margin-right: auto;
  }

  .styled-image {
    border-radius: 20px 5px;
    border: 5px solid var(--color-white);
  }
  .hr-scroll-lists li {
    padding: 0 10px;
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    margin-bottom: 20px;
  }
  .hr-scroll .size-170 {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 40px 10px;
  }
  .hr-scroll .size-170 img {
    border-radius: 40px 10px;
    border: 5px solid var(--color-white);
  }

  .w-50.blb {
    width: 100% !important;
  }
  .radius-40 {
    overflow: hidden;
    border-radius: 18px;
  }
  .mint-price {
    font-size: 20px;
  }

  /*  */
  .sm\:mw-100 {
    width: 100% !important;
  }
  .sm\:max-vh-100 {
    overflow: hidden;
    max-height: calc(100vh - 50px);
  }
  #section-home .sm\:max-vh-100 {
    max-height: calc(100vh - 50px);
  }
  .sm\:mmt-20 {
    margin-top: 1.25rem !important;
  }
  .sm\:mmt-40 {
    margin-top: 2.5rem !important;
  }
  .sm\:mmb-12 {
    margin-bottom: 0.75rem !important;
  }
  .sm\:flex-row {
    flex-direction: row !important;
  }
  .sm\:flex-col {
    flex-direction: column !important;
  }
  .sm\:flex-start {
    align-items: flex-start !important;
  }
  .sm\:justify-center {
    justify-content: center !important;
  }
  .mr-5 {
    margin-right: 20px !important;
  }
  .ml-5 {
    margin-left: 20px !important;
  }
  .sm\:text-center {
    text-align: center !important;
  }
}

@media (max-width: 440px) {
  .section-home-headers {
    max-width: 100%;
  }
  #section-home .mobile-section-home {
    max-width: 300px;
  }
}

@media screen and (min-width: 440px) and (max-width: 540px) {
  .section-home-headers {
    max-width: 100%;
  }
  #section-home .w-550 {
    max-width: 400px;
  }
  .section-home-headers .h-150px {
    height: 90px;
  }
  #section-home .fs-200 {
    font-size: 85px;
    line-height: 70px;
  }

  .welcome-section .fs-180 {
    font-size: 65px;
    line-height: 1;
  }
}

@media (min-width: 540px) and (max-width: 767.99px) {
  .container {
    max-width: 420px !important;
  }
}

@media (max-width: 767.99px) {
  .transitionLine,
  .transitionLine2 {
    display: none;
  }
  .w-95.blb {
    max-width: 100% !important;
  }
}
